import React from 'react'
import styled from 'styled-components'
import { primaryColor } from './Colors'

const SocialsLinkContainer = styled.div`
  border: 1px sold black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: ${primaryColor};
  padding: 10px;
  margin: 10px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #ffffff;
      fill: #ffffff;
      width: 30px;
      height: 30px;
    }
  }
`

const SocialsLink = styled.a`
  color: #ffffff;
  text-decoration: none;
`
const TitleText = styled.span`
  font-size: 2rem;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SocialsButton = ({ title, url, icon }) => (
  <SocialsLink href={url}>
    <SocialsLinkContainer>
      {icon}
      &nbsp;
      <TitleText>{title}</TitleText>
    </SocialsLinkContainer>
  </SocialsLink>
)

export default SocialsButton
