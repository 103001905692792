import React from 'react'
import styled from 'styled-components'
import { primaryColor } from './components/Colors'
import SocialsButton from './components/SocialsButton'
import {
  LogoTwitch,
  LogoTiktok,
  LogoInstagram,
  LogoYoutube,
  LogoDiscord
} from 'react-ionicons'

const WebsiteContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const VideoBackground = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const TitleText = styled.span`
  font-weight: 700;
  font-size: 5rem;
  color: ${(props) => props.color};
`

const socialsList = [
  {
    title: 'Twitch',
    url: 'https://www.twitch.tv/eigen_tv',
    icon: <LogoTwitch />
  },
  {
    title: 'TikTok',
    url: 'https://vt.tiktok.com/ZSe21utuG/',
    icon: <LogoTiktok />
  },
  {
    title: 'Youtube',
    url: 'https://www.youtube.com/channel/UCKvJeTaEa1X1jukkC0AkEyw',
    icon: <LogoYoutube />
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/eigen_tv/',
    icon: <LogoInstagram />
  },
  {
    title: 'Discord',
    url: 'https://discord.gg/K658xFyqjA',
    icon: <LogoDiscord />
  }
]

const App = () => (
  <WebsiteContainer>
    <VideoBackground autoPlay muted loop poster="/background-static.png">
      <source
        src={window.location.origin + '/Videos/background.webm'}
        type="video/webm"
      />
      <source
        src={window.location.origin + '/Videos/background.mp4'}
        type="video/mp4"
      />
    </VideoBackground>
    <TitleContainer>
      <TitleText color="#FFFFFF">Welcome to &nbsp; </TitleText>
      <TitleText color={primaryColor}>EIGEN.TV</TitleText>
    </TitleContainer>
    {socialsList.map(({ title, url, icon }) => (
      <SocialsButton url={url} title={title} icon={icon} key={title} />
    ))}
  </WebsiteContainer>
)

export default App
